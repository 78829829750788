// stores/useImageStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useImageStore = defineStore('images', () => {
  const tickets = ref(0);
  const currentInQueue = ref(0);
  const loadedImages = ref([]);

  const ticket = () => {
    tickets.value++;
    return tickets.value - 1;
  };

  const nextInQueue = () => {
    (currentInQueue.value + 1 < tickets.value) ? currentInQueue.value++ : clearQueue();
  }

  const loaded = (url) => {
    loadedImages.value.push(url);
  }

  const clearQueue = () => {
    tickets.value = 0;
    currentInQueue.value = 0;
  };

  return { ticket, currentInQueue, nextInQueue, clearQueue, loaded, loadedImages };
});
