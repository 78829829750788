import VueSmoothScroll from 'vue3-smooth-scroll'
import Vue3TouchEvents from 'vue3-touch-events';
//import { autoAnimatePlugin } from '@formkit/auto-animate/vue'


export default function registerPlugins(app) {
  app.use(VueSmoothScroll);
  app.use(Vue3TouchEvents);
  //app.use(autoAnimatePlugin);
}
