//YAML Helpers
import yaml from 'js-yaml';
import { isProxy, toRaw } from 'vue';

export const yamlToObject = (string) => yaml.load(string);
export const yamlFirst = (string) => yaml.load(string)[0].trim();

//JSON Helpers
export const cleanUpJSON = (input) => {return JSON.parse(JSON.stringify(input))};

export const objectToArray = (proxyObject) => {return toRaw(proxyObject)};

export const crossOriginSRCSET = (srcset) => {
  // Split the srcset into an array of URL and descriptor pairs
  const srcsetParts = srcset.split(', ');

  // Map each part to a relative URL
  const relativeSrcset = srcsetParts.map(part => {
    // Split part into URL and descriptor
    const [url, descriptor] = part.split(' ');
    // Create a URL object to easily extract the pathname
    const urlObj = new URL(url);
    // Rebuild the part with the relative path and descriptor
    return `${urlObj.pathname} ${descriptor}`;
  });

  // Join the parts back into a srcset string
  return relativeSrcset.join(', ');
}


//URL to PATH
export const pathName = function(input) {
  let url = new URL(input);
  return url.pathname;
}

//Get alternative language
export const alternativeLanguage = function(i) {
  const linkElementForAlternate = document.head.querySelector('link[rel=alternate]');
  return linkElementForAlternate ? linkElementForAlternate.attributes.hreflang.value : false;
}

//Get image from files object by id
export const imageById = function(images, filename) {
  filename = filename.includes('/') ? filename.split('/').pop() : filename;
  const imageObject = images.filter( image => (image.filename == filename))[0];
  if(images){
    return objectToArray(imageObject);
  } else {
    console.error('No images');
  }
}

//URL to PATH
export const files = function(filesObject, by = 'filename') {
  let filesByFileName = {};
  const keys = filesObject ? Object.keys(filesObject) : [];
  for(const key of keys) {
    filesByFileName[filesObject[key][by]] = filesObject[key]
  };
  const files = JSON.parse(JSON.stringify(toRaw(filesByFileName)));
  return files
}

//Local images
export const srcImage = ((url) => {
  return new URL(`/src/${url}`, import.meta.url).href;
});

//Local images
export const publicImage = ((url) => {
  return new URL(`/public/${url}`, import.meta.url).href;
});

//Get page object from path
export const pageFromPath = (path, pages) => {
  if (path, pages) {
    var slugs = path.trim().split('/');
    var page = false;
    var children = pages;
    while (slugs.length) {
      var id = slugs.shift();
      page = children.find ? children.find(o => o.uid === id) : false;
      if (page)
        children = typeof page.children === 'object' ? page.children : [];
    }
    if (page) {
      return page;
    } else {
      //console.error('No page!', path, pages);
    }
  } else {
    console.error('No pages! Add a page element with function', path, pages);
  }
}

//Mix lines with page objects
export const featuresAndLines = function(features, featureSizes) {
  const query = (Array.isArray(features) && features.length && features[0] && features[0].uid!=false );
  const sizes = structuredClone(featureSizes);
  if(query){
    var featureLength = parseInt(features.length);
    var returnArray = [];
    let tween = 0;
    let lineObject = {type: 'line', page: false, uid: false};
    if(featureLength < 100){
      for (let index = 0; index < featureLength; index++) {
        const currentSize = Object.keys(sizes)[0];
        const takeFromLine = 1 / sizes[currentSize].featuresPerLine;
        sizes[currentSize].lines = sizes[currentSize].lines - takeFromLine;;
        const lineOrNot = (sizes[currentSize].lines.toFixed(1) % 1) == 0;
        if(sizes[currentSize].lines==0) {
          delete sizes[currentSize];
        };
        features[index].featureSize = currentSize;
        returnArray.push(features[index]);
        if(lineOrNot) {
          returnArray.push(lineObject);
        }
      }
    }
  }
  return returnArray;
}

//Get page objects with list of paths
export const pagesFromPaths = function(list, pages) {
  if(list !='undefined' && pages.length) {
    let self = this;
    let paths = (typeof list == 'array') ? list : Object.values(list);
    let returnArray = [];
    paths.map(function(path){
      let page = pageFromPath(path, pages);
      if(page) returnArray.push(page);
    });
    //console.log( Array.isArray(returnArray));
    return returnArray;
  }
}
